import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import ThemeOption from '../../utilites/config/themeOption';
import HeaderLogoCockpit from '../../static/images/Logo.png';
import HeaderLogoAER from '../../static/headerLogoAERW.png';
import HeaderLogoTRAV from '../../static/headerLogoTRAVW.png';
import HeaderLogoMUGL from '../../static/headerLogoMUGLW.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { cardsForUser, cardsForAdminMenu } from '../../cardList';

const useStyles = makeStyles((theme) => ({
  HeaderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '60px',
    background: theme.palette.primary.main,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    padding: '0 44px',
    boxSizing: 'border-box',
  },
  menu: {
    display: 'flex',
  },
  LogoBox: {
    display: 'block',
    width: 'auto',
    height: '32px',
  },
  icon: {
    display: 'block',
    fontSize: '52px',
    cursor: 'pointer',
    color: '#ffffff',
    '&:hover': { color: theme.palette.secondary.main },
  },
}));

/**
 * Function to chose header logo depend on office_id of user
 
 * @param {Number} office_id 
 * @default CockpitHeaderLogo
 * @returns {String} Name of header logo
 
 */

function logoChoser(office_id) {
  const logoList = [
    HeaderLogoMUGL,
    HeaderLogoTRAV,
    HeaderLogoAER,
    HeaderLogoCockpit,
  ];
  let logo = null;
  switch (office_id) {
    case 1:
      logo = logoList[0];
      break;
    case 2:
      logo = logoList[1];
      break;
    case 3:
      logo = logoList[2];
      break;
    default:
      logo = logoList[3];
      break;
  }
  return logo;
}

export default function Header({ isRedactor }) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={ThemeOption}>
      <Box className={classes.HeaderBox}>
        <Link href='/mypage'>
          <img className={classes.LogoBox} src={HeaderLogoCockpit} alt='logo' />
        </Link>
        <Box className={classes.menu}>
          {isRedactor ? (
            <>
              {cardsForAdminMenu.map((item, id) => {
                return (
                  <Tooltip key={id} title={item.title}>
                    <Box
                      onClick={() => {
                        if (
                          item.title === 'Wiki' ||
                          item.title === 'E-Shop' ||
                          item.title === 'Intranet' ||
                          item.title === 'pepXpress'
                        ) {
                          window.open(item.link, 'neu', 'popup');
                        } else {
                          window.location.replace(item.link);
                        }
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: '15px',
                          fontSize: '40px',
                          color: '#ffffff',
                        }}
                      >
                        {item.iconForHeader}
                      </Box>
                    </Box>
                  </Tooltip>
                );
              })}
            </>
          ) : (
            <>
              {cardsForUser.map((item, id) => {
                return (
                  <Tooltip key={id} title={item.title}>
                    <Box
                      onClick={() => {
                        if (
                          item.title === 'Wiki' ||
                          item.title === 'E-Shop' ||
                          item.title === 'Intranet' ||
                          item.title === 'pepXpress'
                        ) {
                          window.open(item.link, 'neu', 'popup');
                        } else {
                          window.location.replace(item.link);
                        }
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: '15px',
                          fontSize: '40px',
                          color: '#ffffff',
                        }}
                      >
                        {item.iconForHeader}
                      </Box>
                    </Box>
                  </Tooltip>
                );
              })}
            </>
          )}
          <Tooltip title='Ausloggen'>
            <ExitToAppIcon
              style={{ marginRight: '15px', fontSize: '40px' }}
              onClick={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location.replace('/mypage/logout');
              }}
              className={classes.icon}
            />
          </Tooltip>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

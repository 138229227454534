import React, { ReactElement } from 'react';
import { Grid, CardContent, Typography, Box } from '@material-ui/core';
import { CardForMenu } from '../../interfaces/MenuCard.iterface';
import { Card } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import ThemeOption from '../../utilites/config/themeOption';

export default function MenuCard({
  icon,
  title,
  description,
  link,
}: CardForMenu): ReactElement {
  return (
    <ThemeProvider theme={ThemeOption}>
      <Card
        style={{
          width: '360px',
          height: '220px',
          background: '#F2F2F2',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (
            title === 'Wiki' ||
            title === 'E-Shop' ||
            title === 'Intranet' ||
            title === 'pepXpress'
          ) {
            window.open(link, 'neu', 'popup');
          } else {
            if (link) {
              window.location.replace(link);
            }
          }
        }}
      >
        <CardContent>
          <Grid container direction='column' alignItems='center' spacing={3}>
            <Grid item>
              <Box>{icon()}</Box>
            </Grid>
            <Grid item>
              <Typography variant='h4' color='primary'>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body2'>{description}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
}
